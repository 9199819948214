import { useOpus } from "@/core/composables/useOpus";
import type { OpVendorCartType } from "@/shared/opus";

const { disabledTrackingDomains } = useOpus();

export function getOpusPurposeLocalization(purpose: string): string {
  const purposeKey = purpose.toLowerCase().split(" ").join("_");
  return `shared.opus.quick_connect.purpose_by_key.${purposeKey}`;
}

export function onlyNumbers(event: KeyboardEvent): void {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
}

export function formatPhoneNumber(value: string | null | undefined) {
  if (!value) {
    return "";
  }

  const numericValue = value.replace(/\D/g, "");

  if (numericValue.length <= 3) {
    return numericValue;
  } else if (numericValue.length <= 6) {
    return `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`;
  } else {
    return `${numericValue.slice(0, 3)}-${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
  }
}

export function validatePhoneNumber(value: string | undefined) {
  const regex = /^[0-9-]*$/;
  if (!value) {
    return "Phone number must be 10 digits.";
  } else if (!regex.test(value)) {
    return "Phone must contain only numeric values.";
  }
  return "";
}

export function isTrackingDisabled(email?: string) {
  const domain = getDomainFromEmail(email);

  if (!domain || !disabledTrackingDomains.value) {
    return false;
  }

  const parsedDomains = JSON.stringify(disabledTrackingDomains.value);
  return parsedDomains.includes(domain);
}

export const isPoNumberRequired = (vendorCart: OpVendorCartType) => {
  return !!(
    (vendorCart.vendor?.supplier?.isPONumberMandatory && !vendorCart.vendor?.supplier?.pONumberMandatoryThreshold) ||
    (vendorCart.vendor?.supplier?.isPONumberMandatory &&
      vendorCart.vendor?.supplier?.pONumberMandatoryThreshold &&
      vendorCart.vendor?.supplier?.pONumberMandatoryThreshold < vendorCart.total)
  );
};

function getDomainFromEmail(email?: string) {
  return email?.includes("@") ? email.split("@")[1] : null;
}
