import { computed, ref } from "vue";
import { updateMemberDynamicProperties } from "@/core/api/graphql";
import { useGoogleAnalytics } from "@/core/composables";
import { Logger } from "@/core/utilities";
import { useUser } from "@/shared/account";
import { pageReloadEvent, TabsType, useBroadcast } from "@/shared/broadcast";
import { TERMS_RELEASE_DATE } from "../constants";
import { getDateWithoutTime } from "../utils";
import type { ComputedRef } from "vue";

export enum OpTermsAndConditionsDocumentNames {
  AllTerms = "2024-10-09_All_Terms.pdf",
  Micpa = "2024-10-09_MICPA.pdf",
  TermsOfUse = "2024-10-09_Terms_of_Use.pdf",
  AllTermsPrivate = "2025-02-28_All-Terms-Private-Sector.pdf",
  MicpaPrivate = "2024-10-09_MICPA_private.pdf",
  TermsOfUsePrivate = "2025-01-24_Terms-of-Use-Private-Sector.pdf",
  MembershipAgreementPrivate = "2025-02-06_Private-Sector-Membership-Terms.pdf",
  Empty = "",
}

export function useTermsAndConditions() {
  const loading = ref(false);

  const broadcast = useBroadcast();
  const { user, organization, isPrivateSector, fetchUser } = useUser();
  const ga = useGoogleAnalytics();

  const allTermsDocumentName = isPrivateSector.value
    ? OpTermsAndConditionsDocumentNames.AllTermsPrivate
    : OpTermsAndConditionsDocumentNames.AllTerms;
  const micpaDocumentName = isPrivateSector.value
    ? OpTermsAndConditionsDocumentNames.MicpaPrivate
    : OpTermsAndConditionsDocumentNames.Micpa;
  const termsOfUseDocumentName = isPrivateSector.value
    ? OpTermsAndConditionsDocumentNames.TermsOfUsePrivate
    : OpTermsAndConditionsDocumentNames.TermsOfUse;
  const membershipAgreementDocumentName = isPrivateSector.value
    ? OpTermsAndConditionsDocumentNames.MembershipAgreementPrivate
    : OpTermsAndConditionsDocumentNames.Empty;

  const termsAcceptanceDate = computed(
    () =>
      user?.value?.contact?.dynamicProperties.find((x) => x.name === "TermAndConditionsAcceptDate")?.value as string,
  );

  const isTermsAccepted = computed(() => {
    const acceptDate = user?.value?.contact?.dynamicProperties.find((x) => x.name === "TermAndConditionsAcceptDate")
      ?.value as string;

    if (acceptDate) {
      const formattedCurrentDate = getDateWithoutTime(new Date());
      const termsReleaseDate = TERMS_RELEASE_DATE.split("T")[0];
      const formattedAcceptDate = getDateWithoutTime(new Date(acceptDate));

      if (formattedCurrentDate >= termsReleaseDate && formattedAcceptDate < termsReleaseDate) {
        return false;
      }
    }

    return !!acceptDate;
  });

  const canBypassTermsAndConditionsSection1 = computed(() => !!user?.value?.canBypassTermsAndConditionsSection1);
  const canBypassTermsAndConditionsSection2 = computed(() => !!user?.value?.canBypassTermsAndConditionsSection2);
  const autoAcceptTerms = computed(
    () => canBypassTermsAndConditionsSection1.value && canBypassTermsAndConditionsSection2.value,
  );

  const acceptedSections = computed(() => {
    const dynProp = user?.value?.contact?.dynamicProperties.find((x) => x.name === "TermAndConditionsAcceptSections");
    return dynProp?.value;
  });

  async function acceptTerms(): Promise<void> {
    try {
      loading.value = true;
      const dynamicProperties = [{ name: "TermAndConditionsAcceptDate", value: new Date().toISOString() }];
      const getAcceptSection = (canBypassSection: ComputedRef<boolean>) => (!canBypassSection.value ? "Yes" : "No");
      const acceptSections = `Section1:${getAcceptSection(
        canBypassTermsAndConditionsSection1,
      )};Section2:${getAcceptSection(canBypassTermsAndConditionsSection2)};`;
      dynamicProperties.push({ name: "TermAndConditionsAcceptSections", value: acceptSections });

      await updateMemberDynamicProperties({
        memberId: user.value!.memberId!,
        dynamicProperties: dynamicProperties,
      });
      await fetchUser();
      ga.acceptTermsAndConditions(user.value?.id, organization.value?.outerId);

      void broadcast.emit(pageReloadEvent, null, TabsType.OTHERS);
    } catch (e) {
      Logger.error(`${useTermsAndConditions.name}`, e);
    }
    loading.value = false;
  }

  function downloadDocument(documentName: OpTermsAndConditionsDocumentNames) {
    if (documentName === OpTermsAndConditionsDocumentNames.Empty) {
      return;
    }

    const link = document.createElement("a");
    link.href = `/static/${documentName}`;
    link.download = documentName;
    link.click();
  }

  return {
    loading,
    isTermsAccepted,
    acceptedSections,
    termsAcceptanceDate,
    canBypassTermsAndConditionsSection1,
    canBypassTermsAndConditionsSection2,
    autoAcceptTerms,
    allTermsDocumentName,
    micpaDocumentName,
    termsOfUseDocumentName,
    acceptTerms,
    membershipAgreementDocumentName,
    downloadDocument,
  };
}
