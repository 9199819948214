<template>
  <VcPopover
    :class="{
      'animate-pulse': loading,
    }"
    placement="bottom-start"
    :offset-options="offsetOptions"
    :arrow-enabled="showArrow"
    :disabled="loading || !addresses.length"
    @toggle="onPopupToggle"
  >
    <template #trigger="{ opened }">
      <button type="button" class="flex h-8 items-center gap-1 px-6 text-sm font-bold lg:h-auto lg:px-0">
        <div class="truncate text-additional-50 lg:max-w-xl">
          <span class="text-primary-200">
            <span v-if="selectedAddress">{{ $t("shared.opus.select_location.selector_title") }}</span>
            <span v-else>{{ $t("shared.opus.select_location.empty_selector_title") }}</span>
            <span v-if="selectedAddress && !isMobile">: </span>
          </span>

          <span v-if="selectedAddress && !isMobile" class="text-additional-50">
            {{ selectedAddress.line1 }},
            <template v-if="selectedAddress.line2">{{ selectedAddress.line2 }},</template>
            {{ selectedAddress.city }},
            <template v-if="selectedAddress.regionId">{{ selectedAddress.regionId }},</template>
            {{ selectedAddress.postalCode }}
          </span>
        </div>

        <VcIcon :name="opened ? 'chevron-up' : 'chevron-down'" size="xs" class="text-primary-100" />
      </button>
    </template>

    <template #content="{ close }">
      <div class="w-screen sm:w-[25rem] sm:max-w-none sm:px-0">
        <div
          class="flex h-screen max-h-[calc(100vh-5rem)] flex-col bg-additional-50 text-neutral-950 shadow-2xl md:rounded lg:h-auto lg:max-h-[calc(100vh-3rem)]"
        >
          <div class="flex items-center justify-between border-b px-3.5 py-4">
            <span class="text-base font-bold">
              {{ $t("shared.opus.select_location.widget_title") }}
            </span>
            <VcButton
              v-if="userCanManageAddresses"
              variant="outline"
              color="primary"
              size="xs"
              :to="{ name: 'CompanyInfo' }"
              @click="close()"
            >
              {{ $t("common.buttons.manage_addresses") }}
            </VcButton>
          </div>
          <VcInput
            v-if="searchFieldVisible"
            v-model.trim="searchKeyword"
            class="px-6 py-4"
            size="md"
            maxlength="30"
            :disabled="loading"
            :placeholder="$t('common.placeholders.search')"
            truncate
            clearable
          />
          <div class="relative overflow-y-auto">
            <div
              v-for="item in searchedValues"
              :key="item.id"
              :class="[
                'flex cursor-pointer items-center gap-3 px-6 py-2 hover:bg-primary-50',
                item.id === selectedAddress?.id && 'bg-primary-100',
              ]"
              role="button"
              tabIndex="0"
              @click="
                changeShippingLocation(item.id!);
                close();
              "
              @keyup.esc="close()"
            >
              <VcIcon v-if="item.isFavorite" class="text-primary" name="star" size="md" />
              <div class="w-full flex-1 break-words">
                <p class="text-sm">
                  <span>
                    {{ item.line1 }},
                    <template v-if="item.line2">{{ item.line2 }},</template>
                    {{ item.city }},
                    <template v-if="item.regionId">{{ item.regionId }},</template>
                    {{ item.postalCode }}
                  </span>
                </p>
                <p v-if="item.description" class="mt-0.5 text-xs text-neutral-500">{{ item.description }}</p>
              </div>
            </div>

            <div v-if="isNoResults" class="px-6 py-2 text-neutral-400">
              {{ $t("pages.catalog.no_facet_found_message") }}
            </div>

            <div v-if="isAnchorAdded" ref="fadeVisibilityAnchor"></div>
            <div
              v-if="hasFade"
              class="sticky bottom-0 after:absolute after:bottom-0 after:block after:h-10 after:w-full after:bg-gradient-to-t after:from-additional-50 print:after:content-none"
            ></div>
          </div>

          <template v-if="isShowMoreVisible">
            <div class="flex justify-around p-4 empty:hidden">
              <VcButtonSeeMoreLess v-model="isExpanded" />
            </div>
          </template>
        </div>
      </div>
    </template>
  </VcPopover>
</template>

<script setup lang="ts">
import { useBreakpoints, useElementVisibility } from "@vueuse/core";
import { computed, onMounted, ref, shallowRef } from "vue";
import { BREAKPOINTS } from "@/core/constants";
import { useOpShippingLocation } from "@/shared/opus";
import type { OpusMemberAddressType } from "@/core/api/graphql/types";

interface IProps {
  offsetOptions?: VcPopoverOffsetOptionsType;
  showArrow?: boolean;
}

defineProps<IProps>();
const SHOW_SEARCH_AMOUNT = 10;
const SHOW_MORE_AMOUNT = 9;
const MAX_ITEMS_VISIBLE = 10;

const { loading, addresses, selectedAddress, userCanManageAddresses, init, changeShippingLocation } =
  useOpShippingLocation();
const breakpoints = useBreakpoints(BREAKPOINTS);

const isMobile = breakpoints.smaller("md");
const isExpanded = ref(false);
const searchKeyword = ref("");
const fadeVisibilityAnchor = shallowRef<HTMLElement | null>(null);
const fadeVisibilityAnchorIsVisible = useElementVisibility(fadeVisibilityAnchor);

const searchFieldVisible = computed<boolean>(() => addresses.value.length > SHOW_SEARCH_AMOUNT);
const isShowMoreVisible = computed(() => filtered.value.length > SHOW_MORE_AMOUNT + 1);
const isSearchPerformed = computed(() => searchKeyword.value.length);
const isNoResults = computed(() => !searchedValues.value.length && isSearchPerformed.value);

const filtered = computed(() => {
  return addresses.value.filter((item) => {
    const itemValue = getSearchedLine(item);

    return itemValue.toLowerCase().indexOf((searchKeyword.value || "").toLowerCase()) >= 0;
  });
});

const searchedValues = computed(() => {
  // 1 - for fade at the bottom
  return isExpanded.value ? filtered.value : filtered.value.slice(0, SHOW_MORE_AMOUNT + 1);
});
const isAnchorAdded = computed(() => searchedValues.value.length > MAX_ITEMS_VISIBLE);

const hasFade = computed(
  () =>
    (filtered.value.length > SHOW_MORE_AMOUNT + 1 && !isExpanded.value) ||
    (isAnchorAdded.value && !fadeVisibilityAnchorIsVisible.value),
);

function onPopupToggle(toggle: boolean) {
  if (!toggle) {
    isExpanded.value = false;
    searchKeyword.value = "";
  }
}

function getSearchedLine(item: OpusMemberAddressType): string {
  let result = "";
  item.line1 ? (result += item.line1) : "";
  item.line2 ? (result += item.line2) : "";
  item.city ? (result += item.city) : "";
  item.regionId ? (result += item.regionId) : "";
  item.postalCode ? (result += item.postalCode) : "";
  item.description ? (result += item.description) : "";

  return result.toLowerCase().trim();
}

onMounted(() => init());
</script>
