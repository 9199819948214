import { computed, ref } from "vue";
import { useOpus, useRouteQueryParam } from "@/core/composables";
import { SUPPLIER_SORTING_LIST, SUPPLIER_SORTING_LIST_WITH_RANK } from "@/core/constants";
import { QueryParamName } from "@/core/enums";
import type { OpSuppliersFilterChipsItemType, OpSuppliersFilterDataType } from "../types";
import type { Ref } from "vue";

const { suppliersRankSortingEnabled } = useOpus();
const currentSortingList = computed(() =>
  suppliersRankSortingEnabled.value ? SUPPLIER_SORTING_LIST_WITH_RANK : SUPPLIER_SORTING_LIST,
);

const filterData: Ref<OpSuppliersFilterDataType> = ref({
  isConnected: false,
  sort: currentSortingList.value[0].id,
  categories: [],
  query: "",
});
const appliedFilterData: Ref<OpSuppliersFilterDataType> = ref({ ...filterData.value });

export function useSuppliersFilters() {
  const isFilterEmpty = computed(() => {
    const { isConnected, categories } = appliedFilterData.value;
    return !isConnected && !categories?.length;
  });

  const isFilterDirty = computed(() => {
    return JSON.stringify(filterData.value) !== JSON.stringify(appliedFilterData.value);
  });

  function applyFilters() {
    if (JSON.stringify(appliedFilterData.value) === JSON.stringify(filterData.value)) {
      return;
    }

    appliedFilterData.value = { ...filterData.value };
  }

  const filterChipsItems = computed(() => {
    const items: OpSuppliersFilterChipsItemType[] = [];
    if (appliedFilterData.value.categories?.length) {
      for (const category of appliedFilterData.value.categories) {
        items.push({ fieldName: "categories", value: category, label: category });
      }
    }

    if (appliedFilterData.value.isConnected) {
      items.push({
        fieldName: "isConnected",
        value: appliedFilterData.value.isConnected,
        label: 'Show "Buy now" suppliers',
      });
    }

    return items;
  });

  function resetFilters() {
    filterData.value = { isConnected: false, sort: appliedFilterData.value.sort, categories: [], query: "" };
    appliedFilterData.value = { ...filterData.value };
  }

  function resetDataToApplied() {
    filterData.value = { ...appliedFilterData.value };
  }

  function removeFilterChipsItem(item: OpSuppliersFilterChipsItemType) {
    if (item.fieldName === "isConnected") {
      appliedFilterData.value.isConnected = false;
    }

    if (item.fieldName === "categories") {
      appliedFilterData.value.categories = appliedFilterData.value.categories.filter(
        (category) => category !== item.value,
      );
    }

    filterData.value = { ...appliedFilterData.value };
  }

  return {
    applyFilters,
    filterData,
    isFilterEmpty,
    appliedFilterData: computed(() => appliedFilterData.value),
    isFilterDirty,
    filterChipsItems,
    resetDataToApplied,
    resetFilters,
    removeFilterChipsItem,
    currentSortingList,
  };
}
