import type { RouteRecordRaw } from "vue-router";

const CategoriesAndSuppliers = () =>
  import("@/shared/opus/categories-and-suppliers/components/op-categories-and-suppliers.vue");
const Categories = () => import("@/shared/opus/categories-and-suppliers/components/op-categories.vue");
const Suppliers = () => import("@/shared/opus/categories-and-suppliers/components/op-suppliers.vue");

export const opCategoriesAndSuppliersRoutes: RouteRecordRaw[] = [
  { path: "", name: "CategoriesAndSuppliersHome", component: CategoriesAndSuppliers, meta: { requiresAuth: true } },
  { path: "categories", name: "Category", component: Categories, meta: { requiresAuth: true } },
  {
    path: "supplier-tile",
    name: "Suppliers",
    component: Suppliers,
    props: true,
    meta: { requiresAuth: true },
  },
];
