import { PaymentMethodAvailabilityType } from "@/core/api/graphql/types";
import { OPUS_AGENCY_ADMIN } from "@/core/constants";
import { ContactStatus } from "../types";
import type {
  OpusContactType,
  RoleType,
  SupplierAgencyPaymentMethodType,
  SupplierAgencyType,
} from "@/core/api/graphql/types";
import type {
  ContactDisplayStatusType,
  ExtendedContactType,
  ExtendedSupplierAgencyType,
  OpusSupplierAgencyDisplayPaymentMethod,
} from "@/shared/company";

export function getContactEmailAddresses(contact: OpusContactType): string[] {
  let emails: string[] = [];

  if (contact.emails?.length) {
    emails = contact.emails;
  } else if (contact.securityAccounts?.[0]?.email) {
    emails = [contact.securityAccounts[0].email];
  }

  return emails;
}

export function getFullName(contact: OpusContactType): string {
  return contact.fullName || contact.name || `${contact.firstName} ${contact.lastName}`;
}

export function getContactRoles(contact: OpusContactType): RoleType[] {
  return contact.securityAccounts?.[0]?.roles ?? [];
}

// TODO: move this logic into a Vue component-atom
export function getDisplayContactStatus(status?: string): ContactDisplayStatusType {
  switch (status) {
    case ContactStatus.Approved:
      return {
        localeLabel: "pages.company.members.statuses.active",
        iconName: "check-circle",
        cssStyles: "text-success",
      };

    case ContactStatus.Locked:
      return {
        localeLabel: "pages.company.members.statuses.inactive",
        iconName: "minus-circle",
        cssStyles: "text-danger",
      };

    case ContactStatus.Invited:
      return {
        localeLabel: "pages.company.members.statuses.invited",
        iconName: "dots-circle-horizontal",
        cssStyles: "text-info",
      };

    default:
      return {
        localeLabel: "pages.company.members.statuses.inactive",
        iconName: "minus-circle",
        cssStyles: "text-neutral-500",
      };
  }
}

//OPUS
export function getOpusStatus(contact: OpusContactType) {
  let isActiveAccount: boolean = true;

  if (contact?.securityAccounts?.length) {
    isActiveAccount = !contact?.securityAccounts[0].lockedState;
  }

  if (isActiveAccount && contact.status === ContactStatus.Approved) {
    return ContactStatus.Approved;
  } else if (!isActiveAccount && contact.status === ContactStatus.Approved) {
    return ContactStatus.Locked;
  } else {
    return contact.status;
  }
}
//!OPUS

export function convertToExtendedContact(contact: OpusContactType, fullNameFallback: string): ExtendedContactType {
  const contactFullName: string = getFullName(contact);
  //OPUS
  const opusStatus = getOpusStatus(contact);
  //!OPUS

  return {
    ...contact,
    fullName: contactFullName?.replace(/\s/g, "").length > 0 ? contactFullName : fullNameFallback,
    extended: {
      roles: getContactRoles(contact),
      emails: getContactEmailAddresses(contact),
      displayStatus: getDisplayContactStatus(opusStatus),
      // OPUS
      currentApproverId: contact.orderApproverId || "",
      selectedApproverId: contact.orderApproverId || "",
      opusDisplayRoleId: getDisplayRoleId(contact),
      selectedOpusDisplayRoleId: getDisplayRoleId(contact),
      // !OPUS
    },
  };
}

// OPUS
export function getDisplayRoleId(contact: OpusContactType): string {
  return contact.securityAccounts?.[0]?.roles?.some((role) => role.id === OPUS_AGENCY_ADMIN.id)
    ? "opus-agency-admin"
    : "opus-user";
}

export function convertToExtendedSupplierAgency(supplierAgency: SupplierAgencyType): ExtendedSupplierAgencyType {
  const billingPaymentMethod = supplierAgency.paymentMethods.find((item) => item.name === "PurchaseOrderPaymentMethod");
  const cardPaymentMethod = supplierAgency.paymentMethods.find(
    (item) => item.name === "GetBalancePaymentMethod" || item.name === "SkyflowPaymentMethod",
  );

  return {
    ...supplierAgency,
    extended: {
      displayBillingPaymentMethod: getDisplayPaymentMethod(billingPaymentMethod),
      displayCardPaymentMethod: getDisplayPaymentMethod(cardPaymentMethod),
    },
  };
}

export function getDisplayPaymentMethod(
  method?: SupplierAgencyPaymentMethodType,
): OpusSupplierAgencyDisplayPaymentMethod {
  switch (method?.availability) {
    case PaymentMethodAvailabilityType.Available:
      return {
        title: "pages.company.supplier_management.payment_statuses.available",
        color: "info",
        variant: "outline-dark",
      };

    case PaymentMethodAvailabilityType.Disabled:
      return {
        title: "pages.company.supplier_management.payment_statuses.inactive",
        color: "neutral",
        variant: "outline-dark",
      };

    case PaymentMethodAvailabilityType.InProgress:
      return {
        title: "pages.company.supplier_management.payment_statuses.in_progress",
        color: "success",
        variant: "outline-dark",
      };

    case PaymentMethodAvailabilityType.Active:
      return {
        title: "pages.company.supplier_management.payment_statuses.unavailable",
        color: "accent",
        variant: "outline-dark",
      };

    default:
      return {
        title: "pages.company.supplier_management.payment_statuses.none",
        color: "neutral",
        variant: "outline",
      };
  }
}
// !OPUS
