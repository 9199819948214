export enum QueryParamName {
  Page = "page",
  Sort = "sort",
  Facets = "facets",
  Keyword = "keyword",
  ItemsPerPage = "size",
  SearchPhrase = "q",
  // OPUS
  SubSource = "utm_campaign",
  CreatedWay = "utm_source",
  Sector = "sector",
  SupplierQuery = "sq",
  SupplierParentCategories = "pc",
  SupplierBuyNow = "bn",
  SupplierId = "supplierId",
  // !OPUS
}
