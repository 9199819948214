import { graphqlClient } from "../../../../client";
import toggleFavoriteSupplierForAgencyMutation from "./toggleFavoriteSupplierForAgencyMutation.graphql";
import type { Mutations, MutationsToggleFavoriteSupplierForAgencyArgs } from "@/core/api/graphql/types";

export async function toggleFavoriteSupplierForAgency(supplierOuterId: string): Promise<void> {
  await graphqlClient.mutate<Mutations, MutationsToggleFavoriteSupplierForAgencyArgs>({
    mutation: toggleFavoriteSupplierForAgencyMutation,
    variables: {
      command: {
        supplierOuterId,
      },
    },
  });
}
