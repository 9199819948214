import type { OpusContactType, RoleType, SupplierAgencyType } from "@/core/api/graphql/types";

export enum ContactStatus {
  New = "New",
  Approved = "Approved",
  Locked = "Locked",
  Deleted = "Deleted",
  Invited = "Invited",
}

export type ContactDisplayStatusType = {
  localeLabel: string;
  iconName?: string;
  cssStyles?: string;
};

export type ExtendedContactType = OpusContactType & {
  extended: {
    emails: string[];
    roles: RoleType[];
    displayStatus: ContactDisplayStatusType;
    // OPUS
    opusDisplayRoleId: string;
    currentApproverId: string;
    selectedOpusDisplayRoleId: string;
    selectedApproverId: string;
    // !OPUS
  };
};

// OPUS
// eslint-disable-next-line @typescript-eslint/naming-convention
export type OpusSupplierAgencyDisplayPaymentMethod = {
  title: string;
  color: VcChipColorType;
  variant: VcChipVariantType;
};

export type ExtendedSupplierAgencyType = SupplierAgencyType & {
  extended: {
    displayBillingPaymentMethod: OpusSupplierAgencyDisplayPaymentMethod;
    displayCardPaymentMethod: OpusSupplierAgencyDisplayPaymentMethod;
  };
};

export type OpusDisplayedAgencyUserType = {
  displayName: string;
  approverId: string;
};

// !OPUS
