<template>
  <div class="flex min-h-screen flex-col">
    <!-- Header -->
    <div
      class="sticky top-0 z-10 flex h-14 items-center justify-between gap-3 bg-additional-50 px-6 shadow-md lg:h-auto lg:px-12 lg:py-5 print:relative print:px-0 print:shadow-none"
    >
      <router-link to="/" replace>
        <VcImage :src="logoUrl" :alt="$context.storeName" class="h-9 lg:h-12 print:h-12" lazy />
      </router-link>

      <div v-if="!hideSecureCheckoutLabel" class="flex">
        <div class="flex items-center gap-x-1.5 text-neutral-500 print:-mr-px print:border print:px-2">
          <VcIcon size="xs" name="lock-closed" />

          <span class="hidden text-sm font-bold xs:inline print:inline">
            {{ $t("common.labels.secure_checkout") }}
          </span>
        </div>

        <Created />
      </div>
      <div v-else class="flex">
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <VcIcon class="text-primary" name="opus-account-circle" />

            <span class="ml-2">{{ user.contact?.fullName }}</span>
          </div>

          <div class="grow"></div>

          <VcButton
            :title="$t('shared.layout.header.link_logout')"
            class="ml-4"
            variant="outline"
            color="neutral"
            size="xs"
            icon
            @click="() => signMeOut()"
          >
            <VcIcon name="op-logout" />
          </VcButton>
        </div>
      </div>
    </div>

    <!-- Main Content -->
    <div class="relative flex grow flex-col">
      <slot />
    </div>

    <VcFooter compact />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useWhiteLabeling } from "@/core/composables";
import { useSignMeOut, useUser } from "@/shared/account";
import VcFooter from "../footer/vc-footer.vue";
import Created from "../print/created.vue";
const { logoUrl } = useWhiteLabeling();
// OPUS
const { user } = useUser();
const { signMeOut } = useSignMeOut();
const route = useRoute();
const hideSecureCheckoutLabel = ref(false);

onMounted(() => {
  if (route.name == "TermsAndConditions" || route.name == "TemporaryPass") {
    hideSecureCheckoutLabel.value = true;
  }
});
// !OPUS
</script>
