<template>
  <div class="op-faq justify-center">
    <div class="z-10 flex flex-col">
      <div
        :class="{
          'bg-[url(/static/images/opus/faq/top-header-mobile.png)]': isMobile,
          'bg-[url(/static/images/opus/faq/top-header.png)]': !isMobile,
        }"
        class="z-10 flex justify-center bg-cover bg-no-repeat"
      >
        <div class="mx-auto mb-10 ml-10 mr-0 mt-6 flex w-full max-w-[1300px] flex-col md:mb-14 md:mt-11">
          <h1 class="text-2xl font-light text-additional-50 md:text-4xl">
            {{ $t("pages.faq.header") }}
          </h1>
          <div
            class="mt-6 flex max-w-60 items-center border-l-4 border-[--color-secondary-200] pl-6 text-28 font-bold text-additional-50 md:mt-5 md:h-[80px] md:max-w-full md:pl-10 md:text-5xl"
          >
            <span>{{ $t("pages.faq.subheader") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col justify-center">
      <div class="mx-auto flex w-full max-w-[1300px] flex-col scroll-smooth">
        <div
          id="stickyBlock"
          class="sticky top-[54px] z-[19] flex h-16 w-full flex-row items-center gap-4 overflow-auto whitespace-nowrap text-nowrap bg-additional-50 px-5 sm:gap-10 md:h-24 lg:top-[66px] lg:mb-2 xl:p-0"
        >
          <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions vuejs-accessibility/click-events-have-key-events -->
          <div
            v-for="(menuItem, index) in menuItems"
            :key="index"
            v-html-safe="menuItem.text"
            class="op-faq__menu__content cursor-pointer font-bold text-primary md:text-[23px]"
            @click="scrollTo(menuItem.id)"
          />
        </div>
        <div class="z-10 mb-16 flex flex-col gap-7 md:mb-48">
          <div v-for="item in faqContent" :key="item.id">
            <h2
              :id="item.title"
              class="w-full bg-primary py-[6px] pl-8 text-[22px] font-bold text-additional-50 md:rounded-t"
            >
              {{ item.title }}
            </h2>
            <div
              v-for="text in item.texts"
              :key="text.id"
              :class="{ 'bg-[--color-info-50]': showAnswer[text.id], 'bg-additional-50': !showAnswer[text.id] }"
              class="flex flex-col border border-t-0 border-neutral-100 px-8 py-6 shadow-md last:border-none md:last:rounded-b"
            >
              <div class="flex flex-row items-center justify-start gap-5">
                <div
                  v-html-safe="text.content"
                  class="text-18 font-bold text-[color:#003B57] xs:w-full"
                  :class="{ 'op-faq__information__text_active': showAnswer[text.id] }"
                />
                <button
                  type="button"
                  class="op-faq__information__button ml-auto flex h-[36px] min-w-[36px] items-center justify-center self-end"
                  :class="{ 'op-faq__information__button_active': showAnswer[text.id] }"
                  @click="showAnswer[text.id] = !showAnswer[text.id]"
                >
                  <img
                    :class="{ 'rotate-180': showAnswer[text.id] }"
                    src="/static/images/opus/faq/down.svg"
                    alt="chevron"
                  />
                </button>
              </div>
              <div
                v-if="showAnswer[text.id]"
                class="mt-6 flex flex-col gap-2 transition duration-300 ease-in-out md:flex-row md:gap-5"
              >
                <div v-html-safe="text.answer" class="flex-1 text-17" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { usePageHead } from "@/core/composables";
import { FAQ_CONTENT_PRIVATE_LIST, FAQ_CONTENT_PUBLIC_LIST } from "@/core/constants/opus";
import { useUser } from "@/shared/account/composables/useUser";

const { t } = useI18n();

usePageHead({
  title: t("pages.faq.meta.title"),
});

const { isPrivateSector } = useUser();

interface IFaqBlock {
  id: number;
  content: string;
  answer: string;
}

interface IFaqSection {
  id: number;
  title: string;
  texts: IFaqBlock[];
}

const faqContent = ref<IFaqSection[]>([]);
const showAnswer = ref([false]);
const breakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = breakpoints.smaller("md");

const scrollTo = function (id: string) {
  const element = document.getElementById(id);
  if (element) {
    const elementPosition = element!.getBoundingClientRect().top;
    const currentScrollPosition = window.pageYOffset;
    const offset = elementPosition + currentScrollPosition;
    window.scrollTo({ top: offset - 160, behavior: "smooth" });
  }
};

const privateMenuItems = [
  {
    id: "General",
    text: t("pages.faq.private_sector.navigation_5"),
  },
  {
    id: "Browse & Research",
    text: t("pages.faq.private_sector.navigation_1"),
  },
  {
    id: "Account",
    text: t("pages.faq.private_sector.navigation_3"),
  },
];

const publicMenuItems = [
  {
    id: "General",
    text: t("pages.faq.public_sector.navigation_5"),
  },
  {
    id: "Browse & Research",
    text: t("pages.faq.public_sector.navigation_1"),
  },
  {
    id: "Orders",
    text: t("pages.faq.public_sector.navigation_2"),
  },
  {
    id: "Account",
    text: t("pages.faq.public_sector.navigation_3"),
  },
  {
    id: "Checkout",
    text: t("pages.faq.public_sector.navigation_4"),
  },
];

const menuItems = isPrivateSector.value ? privateMenuItems : publicMenuItems;

faqContent.value = isPrivateSector.value ? FAQ_CONTENT_PRIVATE_LIST : FAQ_CONTENT_PUBLIC_LIST;
</script>

<style lang="scss" scoped>
.op-faq {
  &__menu__content {
    padding-bottom: 1px;
    border-bottom: 1px dotted #006290;
  }

  &__information__text_active {
    font-weight: 700;
  }

  &__information__button {
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid #4f94c7;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 13px 8px 10px 8px;
  }

  &__information__button_active {
    border: 2px solid #006290;
    padding: 13px 8px 10px 8px;
  }
}
</style>
